.modal-enter .modalContent {
    opacity: 0;
    transform: scale(0.9);
  }
  .modal-enter-active .modalContent {
    opacity: 1;
    transform: translateX(0);
    transition: all 0.2s;
  }
  .modal-exit .modalContent {
    opacity: 1;
  }
  .modal-exit-active .modalContent {
    opacity: 0;
    transform: scale(0.9);
    transition: all 0.2s;
  }
