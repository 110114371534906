.swiper-button-next {
  margin-top: 0px;
  position: absolute;
  top: 50%;
  width: 35px;
  height: 45px;
  transform: translateY(-50%);
}

.swiper-button-prev {
  position: absolute;
  top: 50%;
  width: 35px;
  height: 45px;
  transform: translateY(-50%);
  margin-top: 0px;
}

.mySwiper4 .swiper-pagination-bullet {
  background-color: #ffff;
  opacity: 1;
}

.mySwiper4 .swiper-pagination-bullet-active {
  background-color: #ababab;
  opacity: 1;
}

.mySwiper2 .swiper-pagination {
  position: absolute;
  top: 125px;
}

/* target all bullets */
.grupo1 {
  margin-right: 20px;
}

.contenedor {
  width: 100%;
  text-align: center;
  background: #fff;
  border-radius: 5px;
  display: inline-flex;
}
#form-checkout {
  display: flex;
  flex-direction: column;
  max-width: 600px;
}

.oki {
  height: 18px;
  display: inline-block;
  border: 1px solid rgb(118, 118, 118);
  border-radius: 2px;
  padding: 1px 2px;
}

.rbc-toolbar .rbc-btn-group {
  margin-top: 10px;
}

.rbc-toolbar .rbc-toolbar-label {
  margin-top: 10px;
  font-weight: bold;
}

/* Scrollbar styles */
/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #dfe9eb;
}

*::-webkit-scrollbar-track:hover {
  background-color: #dfe9eb;
}

*::-webkit-scrollbar-track:active {
  background-color: #dfe9eb;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #bcbcbc;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #ababab;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #ababab;
}

/* SWIPER */
.swiper-pagination {
  position: relative !important;
  bottom: 0 !important;
}

.mySwiper3 .swiper-button-next {
  margin-top: 0px;
  position: absolute;
  top: 50%;
  width: 35px;
  height: 45px;
  right: 0px;
  transform: translateY(-50%);
}

.mySwiper3 .swiper-button-prev {
  margin-top: 0px;
  position: absolute;
  top: 50%;
  width: 35px;
  height: 45px;
  left: 0px;
  transform: translateY(-50%);
}
