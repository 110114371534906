@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Calibri", sans-serif;
}

/* quitar scroll al modal de rodal */
.rodal-dialog::-webkit-scrollbar {
  display: none;
}

/* dar estilos a la barra de progreso */
progress[value] {
  height: 8px;
}

progress[value]::-webkit-progress-value {
  background-color: rgb(96 165 250);
}

.completado progress[value]::-webkit-progress-value {
  background-color: rgb(34 197 94);
}

progress::-webkit-progress-bar {
  background: rgb(209 213 219);
}

.slider::-webkit-scrollbar {
  display: none;
}

/* Rating stars inline */
svg.star-svg {
  display: inline;
}

/**** VideoPlayer Controls ****/

vm-player {
  --vm-controls-bg: rgba(0, 0, 0, 0.6) !important;
}

.mySwiperProducts .swiper-button-prev,
.mySwiperProducts .swiper-button-next {
  background-color: #63abe6;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.mySwiperProducts .swiper-button-prev {
  left: 0 !important;
}

.mySwiperProducts .swiper-button-next {
  right: 0 !important;
}

.mySwiperProducts .swiper-button-prev::after,
.mySwiperProducts .swiper-button-next::after {
  font-size: 14px;
  font-weight: bold;
}

.mySwiperProducts .swiper-button-prev::after {
  margin-left: -2px;
}

.mySwiperProducts .swiper-button-next::after {
  margin-right: -3px;
}
