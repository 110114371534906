.timepicker {
    padding-top: 1px;
    width: 100%;

}

.timepicker input {
    font-size: 25px;
    padding-top: 24px;
    margin:auto;
    border: 1px solid #bcbcbc;
    color: #474747;
    text-transform: capitalize;
    padding-left: 20px;
}

.timepickerRegister input {
    font-size: 18px;
    padding-top: 10px;
    margin:auto;
    border: 1px solid #bcbcbc;
    color: #ABABAB;
    text-transform: capitalize;
    padding-left: 20px;
}



/* Estilo base */
.timepickerUserProfile input {
    font-size: 18px;
    padding-top: 28px;
    margin: auto;
    border: 1px solid #bcbcbc;
    color: #ABABAB;
    text-transform: capitalize;
    padding-left: 20px;
    width: 100%;
}

/* Pantallas pequeñas (menos de 640px) */
@media (max-width: 640px) {
    .timepickerUserProfile input {
        font-size: 16px;  /* Reducir el tamaño de la fuente */
        padding-top: 12px; /* Reducir el padding */
    }
}

/* Pantallas medianas (entre 641px y 1024px) */
@media (min-width: 641px) and (max-width: 1024px) {
    .timepickerUserProfile input {
        font-size: 17px;  /* Tamaño de fuente intermedio */
        padding-top: 12px; /* Ajuste el padding */
    }
}

/* Pantallas grandes (más de 1024px) */
@media (min-width: 1025px) {
    .timepickerUserProfile input {
        font-size: 18px;  /* Fuente estándar */
        padding-top: 28px; /* Padding original */
    width: 100%;

    }
}