.drop-file-input {
    position: relative;
    width: 100%;
    height: 220px;
    border: 2px dashed #979797;
    display: flex;
    align-items: center;
    justify-content: center;
}
.drop-file-input-pay {
    position: relative;
    width: 50%;
    height: 220px;
    border: 2px dashed #979797;
    display: flex;
    align-items: center;
    justify-content: center;
}

.drop-file-input input {
    justify-content: center;
    align-items: center;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.drop-file-input-pay input {
    justify-content: center;
    align-items: center;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.drop-file-input:hover,
.drop-file-input.dragover {
    opacity: 0.6;
}

.drop-file-input__label {
    text-align: center;
}

.drop-file-input-pay:hover,
.drop-file-input-pay.dragover {
    opacity: 0.6;
}

.drop-file-input__label {
    text-align: center;
}

.drop-file-input__label img {
    width: 100px;
}

.drop-file-preview {
    margin-top: 30px;
}

.drop-file-preview p {
    font-weight: 500;
}

.drop-file-preview__title {
    margin-bottom: 20px;
}

.drop-file-preview__item {
    position: relative;
    display: flex;
    margin-bottom: 10px;
    background-color: #f7f7f7;
    padding: 15px;
}

.drop-file-preview__item img {
    width: 40px;
    height: 40px;
    margin-right: 20px;
}

.drop-file-preview__item__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.drop-file-preview__item__del {
    background-color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.drop-file-preview__item:hover .drop-file-preview__item__del {
    opacity: 1;
}